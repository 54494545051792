<template>
    <section>
        <b-row class="">

            <!-- For Numbers Card -->
            <b-col
                xl="12"
                md="6">
                <FourNumbersCad :data="$store.state.statistics.fourNumbersData"></FourNumbersCad>

            </b-col>
            <!-- / Four Numbers Card -->
        </b-row>
        <b-row class="match-height">

            <!-- Goal Overview Card -->
            <b-col
                xl="4"
                md="6"
            >
                <PercentageCard
                    :data="{completed:$store.state.statistics.complaintsTotal,
                    inProgress:$store.state.statistics.activeComplaintsCount,
                    series:[doneComplaintsPercentage]}">

                </PercentageCard>
            </b-col>
            <!--/ Goal Overview Card -->

            <!-- Top 10 Users -->
            <b-col
                xl="4"
                md="6"
            >
                <TopTenUsers :data="$store.state.statistics.Top10Users">

                </TopTenUsers>
            </b-col>
            <!--/ Top 10 Users -->

            <!-- Top 10 municipality -->
            <b-col
                xl="4"
                md="6"
            >
                <TopTenmunicipalities :data="$store.state.statistics.Top10Municipalities">

                </TopTenmunicipalities>
            </b-col>
            <!--/ Top 10 municipality -->

            <!-- Chart-->
            <b-col xl="12">
                <complaints-chart :data="$store.state.statistics.chartData"
                                  :complaintsCount="$store.state.statistics.complaintsTotal">

                </complaints-chart>
            </b-col>
            <!-- / Chart -->


        </b-row>
    </section>
</template>

<script>
import PercentageCard from './components/percentageCard.vue';
import FourNumbersCad from "./components/FourNumbersCad";
import ComplaintsChart from "./components/complaintsChart";
import TopTenUsers from "./components/TopTenUsers";
import TopTenmunicipalities from "./components/TopTenmunicipalities";
import {BRow, BCol} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "Statistics",
    components: {
        BRow,
        BCol,
        PercentageCard,
        FourNumbersCad,
        ComplaintsChart,
        TopTenUsers,
        TopTenmunicipalities,
    },
    data() {
        return {
            doneComplaintsPercentage: 0,
        }
    },
    created() {
        this.$store.dispatch('statistics/getStatistics')
            .then(res => {
                let doneComplaints = this.$store.state.statistics.complaintsTotal - this.$store.state.statistics.activeComplaintsCount;
                let percentage = Math.round((doneComplaints / this.$store.state.statistics.complaintsTotal) * 100);
                this.doneComplaintsPercentage = percentage;
            })
            .catch(err => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Error'),
                            icon: 'InfoIcon',
                            text: this.$t('Something went wrong'),
                            variant: 'danger',
                        },
                    },
                    {
                        position: 'bottom-right',
                    })
            });
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
