<template>
    <b-card
        v-if="data"
        class="card-transaction"
        no-body
    >
        <b-card-header>
            <b-card-title>{{ $t('Top 10 Active Users') }}</b-card-title>
        </b-card-header>

        <b-card-body>
            <div
                v-for="transaction in data"
                :key="transaction.mode + transaction.id"
                class="transaction-item"
                @click="browseToUser(transaction)"
            >
                <b-media no-body>
                    <b-media-aside>
                        <b-avatar
                            rounded
                            size="42"
                            :variant="transaction.odd?'light-primary':'light-warning'"
                        >
                            <feather-icon
                                size="18"
                                icon="UserIcon"
                            />
                        </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                        <h6 class="transaction-title">
                            {{ transaction.mode }}
                        </h6>
                    </b-media-body>
                </b-media>
                <div
                    class="font-weight-bolder"
                >
                    {{ transaction.payment }}
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
    name: "TopTenUsers",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BMediaBody,
        BMedia,
        BMediaAside,
        BAvatar,
        BDropdown,
        BDropdownItem,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        browseToUser(user) {
            this.$router.push({name: 'complaint-user-info', params: {id: user.id}})
        },
    },
}
</script>

<style scoped>
.media {
    align-items: center;
}

.transaction-item {
    cursor: pointer;
}

</style>
