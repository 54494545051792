<template>
    <b-card :title="$t('TimeLine')">
        <div class="d-flex justify-content-between flex-wrap">
            <div class="mb-1 mb-sm-0">
                <span class="text-muted">{{$t('Timeline of Complaints')}}</span>
            </div>
            <div class="d-flex align-content-center mb-1 mb-sm-0">
                <h1 class="font-weight-bolder ">
                    {{complaintsCount}}
                </h1>
                <div class="pt-25 ml-75">
                    <b-badge variant="light-secondary">
<!--                        <feather-icon-->
<!--                            icon="ArrowDownIcon"-->
<!--                            size="12"-->
<!--                            class="text-danger align-middle"-->
<!--                        />-->
                        <span class="font-weight-bolder align-middle"> {{$t('Complaints')}}</span>
                    </b-badge>
                </div>
            </div>
        </div>

        <!-- echart -->
        <app-echart-line :option-data="data"/>

    </b-card>
</template>

<script>
import {BCard, BBadge} from 'bootstrap-vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue';

export default {
    name: "complaintsChart",
    components: {
        BCard,
        AppEchartLine,
        BBadge,
    },
    props:{
        data: {
            type: Object,
            default: () => ({})
        },
        complaintsCount: {
            type: Number,
            default: 0
        },
    },
}
</script>

<style scoped>

</style>
