<template>
    <b-card
        v-if="data"
        class="card-transaction"
        no-body
    >
        <b-card-header>
            <b-card-title>{{$t('Top 10 Active Municipalities')}}</b-card-title>
        </b-card-header>

        <b-card-body>
            <div
                v-for="transaction in data"
                :key="transaction.mode"
                class="transaction-item"
            >
                <b-media no-body>
                    <b-media-aside>
                        <b-avatar
                            rounded
                            size="42"
                            :variant="transaction.odd?'light-primary':'light-warning'"
                        >
                            <feather-icon
                                size="18"
                                icon="HomeIcon"
                            />
                        </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                        <h6 class="transaction-title">
                            {{ transaction.mode }}
                        </h6>
                        <small>{{ transaction.types }}</small>
                    </b-media-body>
                </b-media>
                <div
                    class="font-weight-bolder"
                >
                    {{ transaction.payment }}
                </div>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BAvatar,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BDropdown, BDropdownItem,
    BMedia,
    BMediaAside,
    BMediaBody
} from "bootstrap-vue";


export default {
    name: "TopTenmunicipalities",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BMediaBody,
        BMedia,
        BMediaAside,
        BAvatar,
        BDropdown,
        BDropdownItem,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
}
</script>

<style scoped>

</style>
